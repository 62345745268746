import axios from 'axios'
import AuthToken from '@/auth/auth-token'

class Batches {
  constructor() {
    const base = axios.create({
      baseURL: process.env.VUE_APP_BASE_API,
      withCredentials: false,
      headers: {
        'Content-type': 'application/json'
      }
    })
    this.tprs = base
  }

  async getBatches(
    adGroupId,
    startDt,
    endDt,
    limit = 100,
    offset = 0,
    requestBody
  ) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch_search',
      method: 'POST',
      data: requestBody,
      params: {
        ad_grp_id: adGroupId,
        start_dt: startDt,
        end_dt: endDt,
        limit: limit,
        offset: offset
      }
    })
  }

  async versionCheck(adGroupId, tprDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/check_tpr_version/${adGroupId}`,
      method: 'GET',
      params: {
        tpr_date: tprDate
      }
    })
  }

  async batchCreate(adGroupId, tprDate, batchName, tprEndDate, batchType) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch',
      method: 'POST',
      params: {
        ad_group_id: adGroupId,
        tpr_date: tprDate,
        tpr_end_date: tprEndDate,
        batch_name: batchName,
        batch_type: batchType
      }
    })
  }

  async batchUpdate(adGroupId, tprDate, batchId, tprEndDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch',
      method: 'POST',
      params: {
        ad_group_id: adGroupId,
        tpr_date: tprDate,
        batch_id: batchId,
        tpr_end_date: tprEndDate
      }
    })
  }

  async deleteBatch(batchId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `tpr/tpr_batch/${batchId}`,
      method: 'DELETE'
    })
  }

  async batchPublish(batchId) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/publish_tpr_report/${batchId}`,
      method: 'POST'
    })
  }

  async updateBatchStatus(batchId, status) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `tpr/batch_status/${batchId}`,
      method: 'PUT',
      params: {
        status: status,
        batch_id: batchId
      }
    })
  }

  async getShuttleAdGroups(adGroupId, tprDate, batchType) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `tpr/getShuttleAdGroups/${adGroupId}`,
      method: 'GET',
      params: {
        tpr_date: tprDate,
        batch_type: batchType
      }
    })
  }

  async getShuttleAdGroupsWithShuttleItems(adGroups, tprDate, itemIds, batchType) {
    await AuthToken.setAuth(this.tprs);
    return this.tprs({
      url: `tpr/getShuttleAdGroupsWithShuttleItems`,
      method: 'POST',
      data: {
        ad_groups: adGroups,
        tpr_date: tprDate,
        items: itemIds,
        batch_type: batchType
      },
    });
  }

  async exportBatch(batchId, batchName, batchType, adGroupName, tprDate, tprEndDate, headers = []) {
    headers.shift()
    headers = headers.filter(header => header !== 'early_buy_allowance' && header !== 'promo_info' && header !== 'comp_pricing_mode')
    const headersString = headers.join(',')
    
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/export/${batchId}`,
      method: 'GET',
      params: {
        batch_name: batchName,
        ad_group_name: adGroupName,
        batch_type: batchType,
        tpr_date: tprDate,
        tpr_end_date: tprEndDate,
        headers: headersString
      }
    })
  }

  async updateBatchName(batchId, batchName){
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: `/tpr/tpr_batch/${batchId}`,
      method: 'PUT',
      params: {
        batch_name: batchName,
        batch_id: batchId
      }
    })
  }

  async getScheduledBatches() {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/tpr_batch_schedule',
      method: 'GET',
      params: {
        upcoming_only: true
      }
    })
  }

  async scheduleBatchCreation(batchType, batchDate) {
    await AuthToken.setAuth(this.tprs)
    return this.tprs({
      url: '/tpr/schedule_batch_creation',
      method: 'POST',
      params: {
        batch_type: batchType,
        tpr_date: batchDate
      }
    })
  }
}

export default new Batches()