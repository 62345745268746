var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "ma-0 pa-0", attrs: { fluid: "" } },
    [
      _c("v-data-table", {
        attrs: {
          expanded: _vm.expanded,
          headers: _vm.displayHeaders,
          height: _vm.tableSize,
          "items-per-page": _vm.pageSize,
          items: _vm.items,
          loading: _vm.loading,
          search: _vm.search,
          "item-class": _vm.getRowClass,
          "loading-text": "Loading Items...Please Wait",
          "loader-color": "orange",
          dense: "",
          "fixed-header": "",
          "hide-default-footer": "",
          "item-key": "id",
          "show-select": "",
          "disable-sort": true,
        },
        scopedSlots: _vm._u(
          [
            _vm._l(_vm.allHeaders, function (header) {
              return {
                key: `header.${header.value}`,
                fn: function () {
                  return [
                    _c(
                      "div",
                      {
                        key: header.value,
                        on: {
                          click: function ($event) {
                            return _vm.handleSort(header)
                          },
                        },
                      },
                      [
                        _vm._v(" " + _vm._s(header.text) + " "),
                        header.showArrow && !header.descending
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "white" } },
                              [_vm._v("mdi-chevron-up")]
                            )
                          : _vm._e(),
                        header.showArrow && header.descending
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "white" } },
                              [_vm._v("mdi-chevron-down")]
                            )
                          : _vm._e(),
                        header.showArrow
                          ? _c(
                              "v-icon",
                              {
                                staticStyle: {
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  cursor: "pointer",
                                },
                                attrs: { color: "red", small: "" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.removeSort(header)
                                  },
                                },
                              },
                              [_vm._v(" mdi-close ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              }
            }),
            {
              key: "top",
              fn: function () {
                return [
                  _c(
                    "v-toolbar",
                    { attrs: { flat: "", color: "toolbar" } },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "flex-right",
                          attrs: { dense: "", align: "center" },
                        },
                        [
                          _c("v-breadcrumbs", {
                            attrs: { large: "", items: _vm.breadcrumbs },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function ({ item }) {
                                  return [
                                    _c(
                                      "v-breadcrumbs-item",
                                      {
                                        attrs: {
                                          href: item.href,
                                          disabled: item.disabled,
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            class: !item.disabled
                                              ? "primary--text"
                                              : "",
                                          },
                                          [_vm._v(_vm._s(item.text))]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("v-spacer"),
                          _vm.hasActiveChanges
                            ? _c(
                                "v-alert",
                                {
                                  staticClass: "mb-2",
                                  attrs: {
                                    type: "warning",
                                    dense: "",
                                    outlined: "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " Warning: Please save changes before leaving Batch View. "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm.isFiltered
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    bottom: "",
                                    "open-on-hover": "",
                                    "max-height": "300",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on }) {
                                          return [
                                            _c(
                                              "v-chip",
                                              _vm._g(
                                                {
                                                  staticClass:
                                                    "orange white--text ml-2",
                                                },
                                                on
                                              ),
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "mr-2",
                                                    attrs: {
                                                      color: "white",
                                                      small: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " mdi-filter-outline "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "text-capitalize",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Results for " +
                                                        _vm._s(
                                                          _vm.searchValues
                                                            .length
                                                        ) +
                                                        " search values "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "v-tooltip",
                                                  {
                                                    attrs: { top: "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "v-btn",
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "white--text ml-2",
                                                                    attrs: {
                                                                      icon: "",
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.searchValues =
                                                                            []
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    "v-icon",
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "mdi-close-circle"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v("Clear Filter"),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    320569755
                                  ),
                                },
                                [
                                  _c(
                                    "v-card",
                                    [
                                      _c(
                                        "v-card-title",
                                        { staticClass: "text-subtitle-2 pb-0" },
                                        [_vm._v(" Search Values: ")]
                                      ),
                                      _c(
                                        "v-list",
                                        _vm._l(
                                          _vm.searchValues,
                                          function (value, index) {
                                            return _c(
                                              "v-list-item",
                                              { key: index },
                                              [
                                                _vm._v(
                                                  " " + _vm._s(value) + " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.tprProTenants
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "grey lighten-2",
                                  on: { click: _vm.openModal },
                                },
                                [_c("v-icon", [_vm._v("mdi-table-edit")])],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-dialog",
                            {
                              attrs: { "max-width": "600px" },
                              model: {
                                value: _vm.showModal,
                                callback: function ($$v) {
                                  _vm.showModal = $$v
                                },
                                expression: "showModal",
                              },
                            },
                            [
                              _c(
                                "v-card",
                                [
                                  _c(
                                    "v-card-title",
                                    {
                                      staticClass:
                                        "main white--text pa-2 centered-title",
                                    },
                                    [_c("span", [_vm._v("Ordering Headers")])]
                                  ),
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-form",
                                        [
                                          _c("v-container", [
                                            _c("div", { staticClass: "row" }, [
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "header-column-name",
                                                    },
                                                    [_vm._v("Display Order")]
                                                  ),
                                                  _c(
                                                    "draggable",
                                                    {
                                                      staticClass: "list-group",
                                                      attrs: {
                                                        list: _vm.displayHeaders,
                                                        group: "headers",
                                                      },
                                                      on: {
                                                        end: _vm.onDragEnd,
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.displayHeaders,
                                                      function (header) {
                                                        return _c(
                                                          "div",
                                                          { key: header.value },
                                                          [
                                                            header.value !==
                                                            "data-table-select"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "list-group-item",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          header.text
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "col-6" },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass:
                                                        "header-column-name",
                                                    },
                                                    [_vm._v("Hide")]
                                                  ),
                                                  _c(
                                                    "draggable",
                                                    {
                                                      staticClass: "list-group",
                                                      attrs: {
                                                        list: _vm.hideHeaders,
                                                        group: "headers",
                                                      },
                                                      on: {
                                                        end: _vm.onDragEnd,
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.hideHeaders,
                                                      function (header) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: header.value,
                                                            staticClass:
                                                              "list-group-item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  header.text
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "main white--text" },
                                          on: { click: _vm.resetHeaders },
                                        },
                                        [_vm._v("Reset")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "green darken-1 white--text",
                                          },
                                          on: { click: _vm.saveHeadersOrder },
                                        },
                                        [_vm._v("Save")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-bottom": 3,
                                value: _vm.srpMenu,
                                bottom: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on: menu, attrs }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on: tooltip }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "grey lighten-2",
                                                            attrs: {
                                                              disabled:
                                                                !_vm.selected
                                                                  .length ||
                                                                _vm.isPublished,
                                                              small: "",
                                                              readonly:
                                                                !_vm.selected
                                                                  .length,
                                                              height: "38",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.srpMenu = true
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        { ...tooltip, ...menu }
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-pencil-box-multiple-outline"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Edit")])]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "fill",
                                  attrs: { width: "200" },
                                },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "pb-0 text-subtitle-1" },
                                    [_vm._v(" Edit ")]
                                  ),
                                  _c(
                                    "v-form",
                                    { ref: "form", staticClass: "px-4 pt-4" },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.multiplierRules,
                                          "background-color": "input",
                                          dense: "",
                                          label: "Multiplier",
                                          outlined: "",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.multiplier,
                                          callback: function ($$v) {
                                            _vm.multiplier = $$v
                                          },
                                          expression: "multiplier",
                                        },
                                      }),
                                      _c("v-text-field", {
                                        attrs: {
                                          rules: _vm.srpRules,
                                          "background-color": "input",
                                          dense: "",
                                          label: "SRP",
                                          outlined: "",
                                          placeholder: "0.00",
                                          prefix: "$",
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.srp,
                                          callback: function ($$v) {
                                            _vm.srp = $$v
                                          },
                                          expression: "srp",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "fill" },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.body,
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              ;(_vm.srp = ""),
                                                (_vm.srpMenu = false)
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "blue white--text mr-2",
                                          class: _vm.body,
                                          on: { click: _vm.bulkUpdate },
                                        },
                                        [_vm._v(" Update ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                "nudge-bottom": 3,
                                value: _vm.deleteMenu,
                                bottom: "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on: menu, attrs }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on: tooltip }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "grey lighten-2",
                                                            attrs: {
                                                              disabled:
                                                                !_vm.selected
                                                                  .length ||
                                                                _vm.isPublished,
                                                              small: "",
                                                              readonly:
                                                                !_vm.selected
                                                                  .length,
                                                              height: "38",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.deleteMenu = true
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        { ...tooltip, ...menu }
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-trash-can-outline"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Delete")])]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  staticClass: "fill",
                                  attrs: { width: "200" },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "black--text text-subtitle-1",
                                      attrs: { align: "center" },
                                    },
                                    [
                                      _vm._v(
                                        " Delete " +
                                          _vm._s(_vm.selected.length) +
                                          " items? "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "fill" },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          class: _vm.body,
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.deleteMenu = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "red white--text mr-2",
                                          class: _vm.body,
                                          on: { click: _vm.bulkDelete },
                                        },
                                        [_vm._v(" Delete ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.tenantsWithTPRSave.includes(`${_vm.$auth.tenant}`)
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    color: "success",
                                    loading: _vm.loading,
                                    disabled:
                                      _vm.hasInvalidInputs || _vm.isPublished,
                                  },
                                  on: { click: _vm.prepareToSave },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-content-save"),
                                  ]),
                                  _vm._v(" Save "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.tenantsWithTPRSave.includes(
                            `${_vm.$auth.tenant}`
                          ) && _vm.hasActiveChanges
                            ? _c(
                                "v-btn",
                                {
                                  staticClass: "ml-2",
                                  attrs: { color: "red", loading: _vm.loading },
                                  on: { click: _vm.refreshPage },
                                },
                                [
                                  _c("v-icon", { attrs: { left: "" } }, [
                                    _vm._v("mdi-refresh-circle"),
                                  ]),
                                  _vm._v(" Reset All "),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isTprProRetailUser
                            ? _c("SearchFilters", {
                                staticClass: "fixed-search-filters",
                                attrs: {
                                  categories: _vm.categoryList,
                                  departments: _vm.departmentList,
                                  promoCategories: _vm.promoCategoryList,
                                  vendors: _vm.vendorList,
                                  batch_type: _vm.selectedBatch.batch_type,
                                },
                                on: { input: _vm.getItems },
                                model: {
                                  value: _vm.searchParams,
                                  callback: function ($$v) {
                                    _vm.searchParams = $$v
                                  },
                                  expression: "searchParams",
                                },
                              })
                            : _c("SearchFilters", {
                                staticClass: "fixed-search-filters",
                                attrs: {
                                  categories: _vm.categoryList,
                                  departments: _vm.departmentList,
                                  promoCategories: _vm.promoCategoryList,
                                  vendors: _vm.vendorList,
                                  batch_type: _vm.selectedBatch.batch_type,
                                },
                                on: { input: _vm.getStoreItems },
                                model: {
                                  value: _vm.searchParams,
                                  callback: function ($$v) {
                                    _vm.searchParams = $$v
                                  },
                                  expression: "searchParams",
                                },
                              }),
                          _c("div", { staticStyle: { width: "10px" } }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on: tooltip }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.showRemoveSortsButton,
                                                expression:
                                                  "showRemoveSortsButton",
                                              },
                                            ],
                                            staticClass: "red lighten-1",
                                            attrs: { small: "", height: "38" },
                                            on: { click: _vm.removeAllSorts },
                                          },
                                          tooltip
                                        ),
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { color: "white" } },
                                            [_vm._v("mdi-sort-variant-remove")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Remove all custom ordering")])]
                          ),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                bottom: "",
                                "nudge-bottom": 3,
                                "close-on-content-click": false,
                                value: _vm.actionMenu,
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on: menu, attrs }) {
                                    return [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { top: "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on: tooltip }) {
                                                  return [
                                                    _c(
                                                      "v-btn",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            staticClass:
                                                              "grey lighten-2",
                                                            attrs: {
                                                              disabled:
                                                                _vm.isSyncing,
                                                              small: "",
                                                              height: "38",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.actionMenu = true
                                                              },
                                                            },
                                                          },
                                                          "v-btn",
                                                          attrs,
                                                          false
                                                        ),
                                                        { ...tooltip, ...menu }
                                                      ),
                                                      [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-cog-outline"
                                                          ),
                                                        ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [_c("span", [_vm._v("Batch Actions")])]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-card",
                                { staticClass: "fill" },
                                [
                                  _c(
                                    "v-list",
                                    { staticClass: "fill" },
                                    _vm._l(
                                      _vm.actions,
                                      function (action, index) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            key: index,
                                            attrs: { link: "" },
                                            on: {
                                              click: function ($event) {
                                                return action.method(
                                                  _vm.selectedBatch
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-list-item-icon",
                                              [
                                                _c("v-icon", [
                                                  _vm._v(_vm._s(action.icon)),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(action.text)),
                                            ]),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("v-divider"),
                ]
              },
              proxy: true,
            },
            {
              key: "loading",
              fn: function () {
                return [
                  _c("v-container", { staticClass: "ml-0" }, [
                    _vm._v(" Loading Items...Please Wait "),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: `header.data-table-select`,
              fn: function ({ on, props }) {
                return [
                  _c(
                    "v-simple-checkbox",
                    _vm._g(
                      _vm._b(
                        { attrs: { ripple: false, dark: "" } },
                        "v-simple-checkbox",
                        props,
                        false
                      ),
                      on
                    )
                  ),
                ]
              },
            },
            {
              key: `item.group_manager_modified`,
              fn: function ({ item, on }) {
                return [
                  _c(
                    "v-simple-checkbox",
                    _vm._g(
                      {
                        attrs: {
                          value: item.group_manager_modified === true,
                          ripple: false,
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
            {
              key: `item.excluded`,
              fn: function ({ item, on }) {
                return [
                  _c(
                    "v-simple-checkbox",
                    _vm._g(
                      {
                        attrs: {
                          value: item.excluded === "" || item.excluded === null,
                          ripple: false,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleExcluded(item)
                          },
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
            {
              key: `item.rules_applied`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        disabled: !_vm.hasRules(item.rules_applied),
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _vm.hasRules(item.rules_applied)
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        { attrs: { color: "success" } },
                                        on
                                      ),
                                      [_vm._v(" mdi-check-circle ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm.hasRules(item.rules_applied)
                        ? _c(
                            "v-card",
                            { attrs: { width: "300" } },
                            [
                              _c(
                                "v-card-title",
                                { staticClass: "text-subtitle-2" },
                                [_vm._v("Applied Rules")]
                              ),
                              _c("v-divider"),
                              _c(
                                "v-list",
                                { attrs: { dense: "" } },
                                _vm._l(
                                  _vm.parseRules(item.rules_applied),
                                  function (rule, index) {
                                    return _c(
                                      "v-list-item",
                                      { key: index },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          { staticClass: "pl-4" },
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(
                                                "Rule: " +
                                                  _vm._s(rule.display_name)
                                              ),
                                            ]),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                "Level: " +
                                                  _vm._s(
                                                    _vm.getRuleDisplay(
                                                      rule.level
                                                    )
                                                  )
                                              ),
                                            ]),
                                            rule.priority != 52
                                              ? _c("v-list-item-subtitle", [
                                                  _vm._v(
                                                    "Value: " +
                                                      _vm._s(rule.displayValue)
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.enforce_compliance`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { disabled: !item.enforce_compliance, top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-icon",
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: item.enforce_compliance
                                          ? "green"
                                          : "grey lighten-1",
                                      },
                                    },
                                    on
                                  ),
                                  [_vm._v(" mdi-shield-check-outline ")]
                                ),
                                _vm.tprProTenants && item.enforce_compliance
                                  ? _c(
                                      "v-icon",
                                      _vm._g(
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: !_vm.SRPIsCompliant(item),
                                              expression:
                                                "!SRPIsCompliant(item)",
                                            },
                                          ],
                                          attrs: {
                                            color: _vm.getWarningColor(item),
                                          },
                                        },
                                        on
                                      ),
                                      [_vm._v(" mdi-exclamation-thick ")]
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [_vm._v("Compliance Enforced")]),
                      _c("br"),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            "Variance: $" + parseFloat(item.variance).toFixed(2)
                          )
                        ),
                      ]),
                    ]
                  ),
                ]
              },
            },
            {
              key: `item.upc`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(item.upc > 2 ? item.upc : "")),
                  ]),
                ]
              },
            },
            {
              key: `item.cost`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v("$" + _vm._s(_vm.formatCurrencyDisplay(item.cost))),
                  ]),
                ]
              },
            },
            {
              key: `item.case_freight`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      "$" +
                        _vm._s(
                          item.case_freight
                            ? _vm.formatCurrencyDisplay(item.case_freight)
                            : _vm.formatCurrencyDisplay(item.cost)
                        )
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.amap_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(_vm.formatCurrencyDisplay(item.amap_allowance)) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.early_buy_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(item.early_buy_allowance)
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_billback_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(item.tpr_billback_allowance)
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_scan_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(item.tpr_scan_allowance)
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_scan_epay_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(
                            item.tpr_scan_epay_allowance
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.tpr_scan_compete_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(
                            item.tpr_scan_compete_allowance
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.edlc_billback_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(
                            item.edlc_billback_allowance
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.edlc_scan_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(item.edlc_scan_allowance)
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.edlc_scan_epay_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(
                            item.edlc_scan_epay_allowance
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.edlc_scan_compete_allowance`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      " $" +
                        _vm._s(
                          _vm.formatCurrencyDisplay(
                            item.edlc_scan_compete_allowance
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.unit_cost`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      "$" + _vm._s(_vm.formatCurrencyDisplay(item.unit_cost))
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.net_unit_cost`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      "$" +
                        _vm._s(_vm.formatCurrencyDisplay(item.net_unit_cost))
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.gross_profit_percent`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      _vm._s(_vm._f("format")(item.gross_profit_percent)) + "%"
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.reg_gross_profit_percent`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      _vm._s(_vm._f("format")(item.reg_gross_profit_percent)) +
                        "%"
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.retail_price`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(
                      "$" + _vm._s(_vm.formatCurrencyDisplay(item.retail_price))
                    ),
                  ]),
                ]
              },
            },
            {
              key: `item.obxi`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(item.obxi)),
                  ]),
                ]
              },
            },
            {
              key: `item.like_code`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(item.like_code)),
                  ]),
                ]
              },
            },
            {
              key: `item.start_dt`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(_vm.formatDateDisplay(item.start_dt))),
                  ]),
                ]
              },
            },
            {
              key: `item.end_dt`,
              fn: function ({ item }) {
                return [
                  _c("td", { staticClass: "noWrap" }, [
                    _vm._v(_vm._s(_vm.formatDateDisplay(item.end_dt))),
                  ]),
                ]
              },
            },
            {
              key: `item.rules_promotion_price`,
              fn: function ({ item }) {
                return [
                  item.rules_promotion_price
                    ? _c("td", { staticClass: "noWrap" }, [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.rules_promotion_price
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.original_promotion_price`,
              fn: function ({ item }) {
                return [
                  item.original_promotion_price && !_vm.isTprProRetailUser
                    ? _c("td", { staticClass: "noWrap" }, [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(
                                item.original_promotion_price
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _c("td", { staticClass: "noWrap" }, [
                        _vm._v(
                          " $" +
                            _vm._s(
                              _vm.formatCurrencyDisplay(item.promotion_price)
                            ) +
                            " "
                        ),
                      ]),
                ]
              },
            },
            {
              key: `item.promotion_price`,
              fn: function ({ item }) {
                return [
                  _c(
                    "td",
                    [
                      _c(
                        "v-row",
                        { staticClass: "my-1 px-1 rounded" },
                        [
                          _c(
                            "v-col",
                            { staticClass: "pa-0", attrs: { cols: "4" } },
                            [
                              !_vm.tenantsWithTPRSave.includes(
                                `${_vm.$auth.tenant}`
                              )
                                ? _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      solo: "",
                                      type: "number",
                                      disabled:
                                        _vm.isPublished ||
                                        (_vm.tprProTenants &&
                                          item.excluded !== ""),
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.multiplierUpdate(
                                          item,
                                          item.multiplier
                                        )
                                        _vm.blurActiveElement()
                                      },
                                      input: function ($event) {
                                        return _vm.updateGrossProfit(item)
                                      },
                                    },
                                    model: {
                                      value: item.multiplier,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "multiplier",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "item.multiplier",
                                    },
                                  })
                                : _c("v-text-field", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      solo: "",
                                      type: "number",
                                      disabled:
                                        _vm.isPublished ||
                                        (_vm.tprProTenants &&
                                          item.excluded !== ""),
                                      value: item.multiplier,
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleInput(
                                          item,
                                          { target: { value: $event } },
                                          "multiplier"
                                        )
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "v-icon",
                            { staticClass: "mx-2", attrs: { size: "20" } },
                            [_vm._v("mdi-at")]
                          ),
                          _c(
                            "v-col",
                            { staticClass: "pa-0" },
                            [
                              !_vm.tenantsWithTPRSave.includes(
                                `${_vm.$auth.tenant}`
                              )
                                ? _c("v-text-field", {
                                    staticClass: "px-0",
                                    attrs: {
                                      readonly: _vm.selected.length > 0,
                                      rules: _vm.srpRules,
                                      prefix: "$",
                                      "background-color": "input",
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      solo: "",
                                      step: ".01",
                                      type: "number",
                                      disabled:
                                        _vm.isPublished ||
                                        (_vm.tprProTenants &&
                                          item.excluded !== ""),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.updateGrossProfit(item)
                                      },
                                      change: function ($event) {
                                        _vm.srpUpdate(
                                          item,
                                          item.promotion_price
                                        )
                                        _vm.blurActiveElement()
                                      },
                                    },
                                    model: {
                                      value: item.promotion_price,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          item,
                                          "promotion_price",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "item.promotion_price",
                                    },
                                  })
                                : _c("v-text-field", {
                                    key: _vm.srprefresh,
                                    staticClass: "px-0",
                                    attrs: {
                                      readonly: _vm.selected.length > 0,
                                      rules: _vm.srpRules,
                                      value: item.promotion_price,
                                      prefix: "$",
                                      "background-color": "input",
                                      dense: "",
                                      outlined: "",
                                      "hide-details": "",
                                      solo: "",
                                      step: ".01",
                                      type: "number",
                                      disabled:
                                        _vm.isPublished ||
                                        (_vm.tprProTenants &&
                                          item.excluded !== ""),
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.handleInput(
                                          item,
                                          { target: { value: $event } },
                                          "promotion_price"
                                        )
                                      },
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { top: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _vm.tenantsWithTPRSave.includes(
                                          `${_vm.$auth.tenant}`
                                        )
                                          ? _c(
                                              "v-btn",
                                              _vm._g(
                                                {
                                                  style: {
                                                    visibility:
                                                      _vm.changes.some(
                                                        (change) =>
                                                          change.id === item.id
                                                      )
                                                        ? "visible"
                                                        : "hidden",
                                                  },
                                                  attrs: { icon: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.resetRow(item)
                                                    },
                                                  },
                                                },
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-refresh-circle"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v("Reset Values")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: `item.comp_pricing_mode`,
              fn: function ({ item }) {
                return [
                  item.comp_pricing_mode !== "N/A"
                    ? _c(
                        "td",
                        { staticClass: "noWrap" },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _vm.tenantsWithTPRSave.includes(
                                          _vm.$auth.tenant
                                        )
                                          ? _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      color:
                                                        _vm.getPriceComparisonColor(
                                                          item
                                                        ),
                                                    },
                                                  },
                                                  "v-icon",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.getPriceComparisonIcon(
                                                        item
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.getPriceComparisonText(item))
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("td", [
                    item.comp_pricing_mode !== "N/A"
                      ? _c("span", [
                          _vm._v(
                            "$" +
                              _vm._s(
                                _vm.formatCurrencyDisplay(
                                  item.comp_pricing_mode
                                )
                              )
                          ),
                        ])
                      : _c("span", [_vm._v("N/A")]),
                  ]),
                ]
              },
            },
            {
              key: `item.promo_info`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-content-click": false,
                        "offset-x": "",
                        "offset-y": "",
                        "open-on-click": "",
                        right: "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: menu, attrs }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                        icon: "",
                                                        tabindex: "-1",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getPromosByRow(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  { ...tooltip, ...menu }
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-magnify"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("View Promos")])]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        { attrs: { width: "400" } },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "accent",
                              attrs: { flat: "", dark: "" },
                            },
                            [
                              _c("v-toolbar-title", [
                                _vm._v("Included in Allowances"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-divider"),
                          _c(
                            "v-container",
                            {
                              staticClass: "ma-0 pa-0",
                              staticStyle: {
                                "max-height": "250px",
                                "scroll-y": "auto",
                              },
                              attrs: { fluid: "" },
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "py-0", attrs: { dense: "" } },
                                _vm._l(_vm.promosByRow, function (promo) {
                                  return _c(
                                    "v-list-item",
                                    {
                                      key: promo.promo_id,
                                      attrs: { link: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openLink(promo.promo_id)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-list-item-content",
                                        { staticClass: "primary--text" },
                                        [
                                          _c("v-list-item-title", [
                                            _vm._v(_vm._s(promo.promo_name)),
                                          ]),
                                          _vm.promosByRow.length > 0
                                            ? _c("v-list-item-subtitle", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(promo.promo_number) +
                                                    " "
                                                ),
                                              ])
                                            : _c("v-list-item-subtitle", [
                                                _vm._v(" No Promos to Show "),
                                              ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
            _vm.tenant == "alliance-retail-group"
              ? {
                  key: `item.is_shipper`,
                  fn: function ({ item }) {
                    return [
                      item.is_shipper
                        ? _c(
                            "span",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { icon: "", color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.selectedItem = item
                                      _vm.showComponents = true
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-view-list")])],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                }
              : null,
            !_vm.isTprProRetailUser
              ? {
                  key: "expanded-item",
                  fn: function ({ item, headers }) {
                    return [
                      _c(
                        "td",
                        {
                          staticClass: "grey lighten-3 pa-0",
                          attrs: { colspan: headers.length },
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              tabindex: "-1",
                              "background-color": "grey lighten-3",
                              color: "primary",
                              dense: "",
                              flat: "",
                              "hide-details": "",
                              placeholder: "Add Note",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.noteUpdate(item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prepend-inner",
                                  fn: function () {
                                    return [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "font-weight-medium mx-3 pt-1",
                                        },
                                        [_vm._v("Note:")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: item.note,
                              callback: function ($$v) {
                                _vm.$set(item, "note", $$v)
                              },
                              expression: "item.note",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                }
              : null,
            {
              key: "footer",
              fn: function () {
                return [
                  _c("v-divider"),
                  _c(
                    "v-toolbar",
                    {
                      staticStyle: { "margin-top": "16px", height: "56px" },
                      attrs: { flat: "", color: "#fff" },
                    },
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "align-center",
                          staticStyle: { height: "100%" },
                        },
                        [
                          _c("v-spacer"),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center py-0",
                              staticStyle: { "max-width": "150px" },
                              attrs: { cols: "2" },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  items: _vm.pageSizes,
                                  "menu-props": {
                                    top: true,
                                    offsetY: true,
                                    maxHeight: 500,
                                  },
                                  dense: "",
                                  height: "32",
                                  label: "Items Per Page",
                                },
                                model: {
                                  value: _vm.pageSize,
                                  callback: function ($$v) {
                                    _vm.pageSize = $$v
                                  },
                                  expression: "pageSize",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "d-flex align-center py-0",
                              staticStyle: { "margin-top": "-26px" },
                              attrs: { cols: "auto" },
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    disabled: _vm.disablePrevious,
                                    icon: "",
                                    large: "",
                                  },
                                  on: { click: _vm.previous },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-left")])],
                                1
                              ),
                              _c("small", [_vm._v("Page " + _vm._s(_vm.page))]),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "mx-2",
                                  attrs: {
                                    disabled: _vm.disableNext,
                                    icon: "",
                                    large: "",
                                  },
                                  on: { click: _vm.next },
                                },
                                [_c("v-icon", [_vm._v("mdi-chevron-right")])],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "400" },
          model: {
            value: _vm.showShuttlePopup,
            callback: function ($$v) {
              _vm.showShuttlePopup = $$v
            },
            expression: "showShuttlePopup",
          },
        },
        [
          _c(
            "v-card",
            {
              staticClass: "d-flex flex-column",
              staticStyle: { "min-height": "220px" },
            },
            [
              _c(
                "v-toolbar",
                { staticClass: "main", attrs: { flat: "", dark: "" } },
                [_c("v-toolbar-title", [_vm._v("Shuttle?")])],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass:
                    "d-flex align-center justify-center flex-grow-1 pa-4",
                },
                [
                  _c("p", { staticClass: "text-center ma-0" }, [
                    _vm._v(
                      "Would you like to shuttle these changes to other Ad Groups?"
                    ),
                  ]),
                ]
              ),
              _c(
                "v-card-actions",
                { staticClass: "flex-grow-0 pa-4" },
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-btn",
                            { on: { click: _vm.cancelShuttleChanges } },
                            [_vm._v("No")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3 green white--text",
                              on: { click: _vm.confirmShuttleChanges },
                            },
                            [_vm._v(" Yes ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "400" },
          model: {
            value: _vm.showConfirmPopup,
            callback: function ($$v) {
              _vm.showConfirmPopup = $$v
            },
            expression: "showConfirmPopup",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "modalCard", attrs: { height: "220" } },
            [
              _c(
                "v-toolbar",
                { staticClass: "main", attrs: { flat: "", dark: "" } },
                [_c("v-toolbar-title", [_vm._v("Confirm SRP Values")])],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { staticClass: "align-center" },
                        [
                          _c("v-checkbox", {
                            attrs: {
                              id: "checkbox",
                              label:
                                "I understand that I am saving items with an SRP value that falls outside of the compliance range.",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.confirmChecked,
                              callback: function ($$v) {
                                _vm.confirmChecked = $$v
                              },
                              expression: "confirmChecked",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-row",
                    { attrs: { justify: "center" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("v-btn", { on: { click: _vm.cancelConfirm } }, [
                            _vm._v("Cancel"),
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-3 green white--text",
                              attrs: { disabled: !_vm.confirmChecked },
                              on: { click: _vm.saveChanges },
                            },
                            [_vm._v(" Confirm Save ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("AlertDialog", {
        attrs: {
          value: _vm.alertDialog,
          batch: _vm.selectedBatch,
          total: _vm.total,
        },
        on: {
          "update:value": function ($event) {
            _vm.alertDialog = $event
          },
          update: function ($event) {
            return _vm.batchUpdate($event)
          },
        },
      }),
      _c("AdGroupShuttle", {
        attrs: {
          value: _vm.adShuttleDialog,
          ad_group: _vm.selectedAdGroup,
          batch: _vm.selectedBatch,
          batch_changes: _vm.changesAvailableForShuttle,
        },
        on: {
          "update:value": function ($event) {
            _vm.adShuttleDialog = $event
          },
        },
      }),
      _c(
        "v-overlay",
        { attrs: { absolute: "", value: _vm.exporting } },
        [
          _c(
            "v-progress-circular",
            { attrs: { indeterminate: "", size: "100" } },
            [_vm._v(" Exporting... ")]
          ),
        ],
        1
      ),
      _vm.showComponents
        ? _c("ComponentItems", {
            attrs: {
              selectedItem: _vm.selectedItem,
              selectedBatch: _vm.selectedBatch,
            },
            on: {
              close: function ($event) {
                _vm.showComponents = false
              },
            },
            model: {
              value: _vm.showComponents,
              callback: function ($$v) {
                _vm.showComponents = $$v
              },
              expression: "showComponents",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }