<template>
  <v-row no-gutters>
    <v-col cols="11" class="pa-0">
      <v-select
        v-model="selectedFilters"
        label="Search Filters"
        :items="availableFilters"
        item-text="name"
        item-value="key"
        background-color="input"
        :menu-props="menuProps"
        :disabled="disabled"
        multiple
        outlined
        hide-details
        clearable
        small-chips
        @click:clear="removeAllFilters"
        dense
        style="border-top-right-radius: 0px !important; border-bottom-right-radius: 0px !important;"
        class="scrollable-select">
        <!-- Custom Selection Rendering -->
        <template v-slot:selection="{ attrs, item }">
          <v-chip
            v-bind="attrs"
            :color="item.color || null"
            :dark="Boolean(item.color)"
            small>
            {{ getFilterText(item) }}
            <v-icon small right @click="removeFilter(item.key, true)">
              mdi-close
            </v-icon>
          </v-chip>
        </template>

        <!-- Custom Item Rendering -->
        <template v-slot:item="{ item, on, attrs }">
          <v-list-item>
            <v-list-item-action>
              <!-- Shipper Checkbox -->
              <v-simple-checkbox
                v-if="item.key === 'is_shipper'"
                v-model="params.is_shipper"
                label="Shipper"
                color="primary"
                :ripple="false"
              ></v-simple-checkbox>


              <!-- Other Checkbox Items -->
              <v-simple-checkbox
                v-else
                :value="isFilterActive(item.key)"
                :disabled="isCheckboxDisabled(item)"
                color="primary"
                :ripple="false"
              ></v-simple-checkbox>
            </v-list-item-action>

            <v-list-item-content style="overflow: visible">
              <v-autocomplete
                v-if="item.key === 'category'"
                v-model="params.category"
                :label="item.name"
                :items="sortedCategories"
                item-text="name"
                item-value="id"
                multiple
                small-chips
                deletable-chips
                hide-details
                clearable
                outlined
                dense
                @change="handleCategoryChange"
                @click:clear="removeFilter(item.key, true)"
              >
                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-action>
                      <v-checkbox :value="attrs.inputValue" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-else-if="item.key === 'department'"
                v-model="params.department"
                :label="item.name"
                :items="sortedDepartments"
                item-text="name"
                item-value="id"
                multiple
                small-chips
                deletable-chips
                hide-details
                clearable
                outlined
                dense
                @change="handleDepartmentChange"
                @click:clear="removeFilter(item.key, true)"
              >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :value="attrs.inputValue" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-else-if="item.key === 'promo_category_name'"
                v-model="params.promo_category_name"
                :label="item.name"
                :items="sortedPromoCat"
                item-text="name"
                item-value="id"
                multiple
                small-chips
                deletable-chips
                hide-details
                clearable
                outlined
                dense
                @change="handlePromoCatChange"
                @click:clear="removeFilter(item.key, true)"
              >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                    <v-list-item-action>
                      <v-checkbox :value="attrs.inputValue" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-else-if="item.key === 'vendor'"
                v-model="params.vendor"
                :label="item.name"
                :items="sortedVendors"
                item-text="name"
                item-value="id"
                multiple
                small-chips
                deletable-chips
                hide-details
                clearable
                outlined
                dense
                @change="handleVendorChange"
                @click:clear="removeFilter(item.key, true)"
              >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-action>
                    <v-checkbox :value="attrs.inputValue" />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
          
              
              <!-- Private Label AMAPs Dropdown -->
              <v-autocomplete
                v-else-if="item.key === 'private_label_indicator'"
                v-model="params.private_label_indicator"
                :label="item.name"
                :items="[{ text: 'Only Include Private Label AMAPs', value: true }, { text: 'Exclude Private Label AMAPs', value: false }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
                clearable
                @change="handleIsPrivateLabelChange"
                @click:clear="removeFilter(item.key, true)"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item.text }}</span>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-else-if="item.key === 'vmc_only'"
                v-model="params.vmc_only"
                :label="item.name"
                :items="[{ text: 'Only Include VMC Items', value: true }, { text: 'Exclude VMC Items', value: false }]"
                item-text="text"
                item-value="value"
                outlined
                dense
                hide-details
                clearable
                @change="handleVmcOnlyChange"
                @click:clear="removeFilter(item.key, true)"
              >
                <template v-slot:selection="{ item }">
                  <span>{{ item.text }}</span>
                </template>
              </v-autocomplete>
              <span
                v-else-if="item.key === 'is_shipper'"
                :value="params.is_shipper"
                :label="item.name"
              >
                Shippers/Pallets
              </span>
              <span
                v-else-if="item.key === 'private_label_indicator'"
                :value="params.private_label_indicator"
                :label="item.name"
              >
                Private Label AMAPs
              </span>
              <span
                v-else-if="item.key === 'vmc_only'"
                :value="params.vmc_only"
                :label="item.name"
              >
                VMC Items Only
              </span>
              <!-- Textarea that auto-grows and handles Enter key -->
              <v-textarea
                v-else
                v-model="params[item.key]"
                :label="item.name"
                hide-details="auto"
                background-color="input"
                :prefix="item.prefix || null"
                :type="item.type"
                :autofocus="(selectedFilters.length === 0) && item.key === 'name'"
                dense
                :clearable="item.key === 'some_key' ? (params[item.key] !== true) : true"
                @input="attrs.inputValue ? null : handleInputValue(item)"
                @keydown="handleKeydown($event, item.key)"
                @click:clear="removeFilter(item.key, true)"
                outlined
                auto-grow
                rows="1"
                :hint="(item.key !== 'notes' && item.key !== 'description') ? 'Press \'Enter\' to add multiple search criteria.' : ''"
              ></v-textarea>
            </v-list-item-content>
          </v-list-item>
        </template>
        <template v-if="selectedFilters.length">
          <v-icon small right @click="removeAllFilters" style="cursor: pointer; margin-right: 8px;">
            mdi-close
          </v-icon>
        </template>
      </v-select>
    </v-col>

    <v-col cols="1" class="pa-0">
      <v-btn
        height="40"
        depressed
        color="accent"
        style="border-top-left-radius: 0px; border-bottom-left-radius: 0px; border: 1px solid #9e9e9e; border-left: 0px;"
        @click="triggerSearch"
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'SearchFilters',
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    categories: Array,
    departments: Array,
    promoCategories: Array,
    vendors: Array,
    batch_type: String,
  },
  data() {
    return {
      selectMenu: false,
      selectedFilters: [],
      filters: [
        { name: 'Promo Category', key: 'promo_category_name', color: 'cyan darken-1' },
        { name: 'Department', key: 'department', type: 'text' },
        { name: 'Category', key: 'category', color: 'amber darken-2' },
        { name: 'Vendor', key: 'vendor', color: 'amber darken-2' },
        ...(this.$auth.tenant === 'alliance-retail-group'
          ? [{ name: 'Private Label AMAPs', key: 'private_label_indicator' }]
          : []),
        ...(this.$auth.tenant === 'alliance-retail-group' && this.batch_type == 'NON PERISHABLE'
          ? [{ name: 'VMC Items', key: 'vmc_only' }]
          : []),
        { name: 'Linked Group', key: 'linked_group', color: 'cyan darken-1' },
        { name: 'Item ID', key: 'item_id', type: 'text' },
        { name: 'UPC', key: 'upc', type: 'text' },
        ...(this.$auth.tenant === 'alliance-retail-group'
          ? [{ name: 'Order Book Index', key: 'order_book_index', color: 'amber darken-2' }]
          : []),
        { name: 'Description', key: 'description', color: 'red' },
        { name: 'Notes', key: 'notes', color: 'red' },
          { name: 'Shippers/Pallets', key: 'is_shipper', color: 'amber darken-2' },
      ],
      params: {
        department: null,
        category: null,
        linked_group: null,
        item_id: null,
        upc: null,
        description: null,
        is_shipper: null,
        notes: null,
        obxi: null,
        promo_category_name: null,
        private_label_indicator: null,
        vendor: null,
        vmc_only: null,
      },
    };
  },
  computed: {
    menuProps() {
      return {
        value: this.selectMenu,
        offsetY: true,
        maxHeight: '800px',
        maxWidth: '900px',
        closeOnContentClick: false,
        closeOnClick: true,
        openOnClick: true,
      };
    },
    availableFilters() {
      return this.filters.filter(f => (!f.tenants || f.tenants.includes(this.$auth.tenant)));
    },
    activeFilters() {
      return Object.keys(this.params).filter(this.isActive);
    },
    sortedCategories() {
      let categories = [...this.categories.map(cat => {
        if (typeof cat === 'string') {
          return { name: cat, disabled: false };
        }
        cat.disabled = cat.disabled || false;
        return cat;
      })];

      categories = this.sortByKey(categories, 'name');

      return categories;
    },
    sortedDepartments() {
      let departments = [...this.departments.map(dep => {
        if (typeof dep === 'string') {
          return { name: dep, disabled: false };
        }
        dep.disabled = dep.disabled || false;
        return dep;
      })];

      departments = this.sortByKey(departments, 'name');
      return departments;
    },
    sortedPromoCat() {
      let promos = [...this.promoCategories.map(dep => {
        if (typeof dep === 'string') {
          return { name: dep, disabled: false };
        }
        dep.disabled = dep.disabled || false;
        return dep;
      })];

      promos = this.sortByKey(promos, 'name');
      return promos;
    },
    sortedVendors() {
      let vendors = [...this.vendors.map(vendor => {
        if (typeof vendor === 'string') {
          return { name: vendor, disabled: false };
        }
        vendor.disabled = vendor.disabled || false;
        return vendor;
      })];

      vendors = this.sortByKey(vendors, 'name');
      return vendors;
    },
    isTprProRetailUser() {
      return this.userGroups.includes('tpr-manager.pro')
    },
  },
  methods: {
    handleKeydown(event, key) {
      if (event.key === 'Enter') {
        if (key === 'notes' || key === 'description') {
          event.preventDefault();
        }
      }
    },
    sortByKey(array, key) {
      return array.sort((a, b) => a[key].localeCompare(b[key]));
    },
    handleCategoryChange(selectedCategories) {
      this.params.category = selectedCategories;
      if (selectedCategories.length > 0 && !this.selectedFilters.includes('category')) {
        this.selectedFilters.push('category');
      } else if (selectedCategories.length === 0) {
        this.removeFilter('category', true);
      }
    },
    handleDepartmentChange(selectedDepartments) {
      this.params.department = selectedDepartments;
      if (selectedDepartments.length > 0 && !this.selectedFilters.includes('department')) {
        this.selectedFilters.push('department');
      } else if (selectedDepartments.length === 0) {
        this.removeFilter('department', true);
      }
    },
    handlePromoCatChange(selectedPromoCats) {
      this.params.promo_category_name = selectedPromoCats;
      if (selectedPromoCats.length > 0 && !this.selectedFilters.includes('promo_category_name')) {
        this.selectedFilters.push('promo_category_name');
      } else if (selectedPromoCats.length === 0) {
        this.removeFilter('promo_category_name', true);
      }
    },
    handleVendorChange(selectedVendors) {
      this.params.vendor = selectedVendors;
      if (selectedVendors.length > 0 && !this.selectedFilters.includes('vendor')) {
        this.selectedFilters.push('vendor');
      } else if (selectedVendors.length === 0) {
        this.removeFilter('vendor', true);
      }
    },
    handleIsShipperChange(value) {
      if (value === null || value == false) {
        this.removeFilter('is_shipper', true);
      } else {
        if (!this.selectedFilters.includes('is_shipper')) {
          this.selectedFilters.push('is_shipper');
        }
      }
    },
    handleIsPrivateLabelChange(value) {
      if (value === null) {
        this.removeFilter('private_label_indicator', true);
      } else {
        if (!this.selectedFilters.includes('private_label_indicator')) {
          this.selectedFilters.push('private_label_indicator');
        }
        this.params.private_label_indicator = value;
      }
    },
    handleVmcOnlyChange(value) {
      if (value === null) {
        this.removeFilter('vmc_only', true);
      } else {
        if (!this.selectedFilters.includes('vmc_only')) {
          this.selectedFilters.push('vmc_only');
        }
        this.params.vmc_only = value;
      }
    },
    removeFilter(key, clearValue = false) {
      const index = this.selectedFilters.indexOf(key);

      this.selectedFilters.splice(index, 1);
      if (clearValue) {
        this.params[key] = null;
        this.triggerSearch();
      }
    },
    removeAllFilters() {
      // Clear all filters in params by setting them to null
      Object.keys(this.params).forEach(key => {
        this.params[key] = null;
      });

      // Clear the selectedFilters array
      this.selectedFilters = [];

      // Trigger the search to reflect the changes
      this.triggerSearch();

      // Clear any stored filters in sessionStorage
      sessionStorage.removeItem('search_filters');
    },
    isCheckboxDisabled(item) {
      if (Array.isArray(this.params[item.key])) {
        return false;
      }
      return !(this.params[item.key] !== null) && (item.key !== 'some_key');
    },
    isActive(key) {
      if (this.isSelected(key) && Boolean(this.params[key])) {
        const value = this.params[key];
        return Array.isArray(value) ? (value.length > 0) : true;
      }
      return false;
    },
    isFilterActive(key) {
      if (key === 'category') {
        return this.params.category && this.params.category.length > 0;
      }
      if (key === 'department') {
        return this.params.department && this.params.department.length > 0;
      }
      if (key === 'is_shipper') {
        return this.params.is_shipper !== null;
      }
      if (key === 'vmc_only') {
        return this.params.vmc_only !== null; 
      }
      if (key === 'private_label_indicator') {
        return this.params.private_label_indicator !== null; 
      }
      return Boolean(this.params[key]);
    },
    getFilterText(item) {
      return `${item.name}`;
    },
    isSelected(key) {
      return this.selectedFilters.includes(key);
    },
    handleInputValue(item) {
      if (this.params[item.key] === "") {
        this.params[item.key] = null;
        this.removeFilter(item.key);
      } else if (Boolean(this.params[item.key]) && !this.isSelected(item.key)) {
        this.selectedFilters.push(item.key);
      }
    },
    triggerSearch() {
      this.$emit('input', { ...this.params });
      this.updateSearchFilters();
    },
    updateSearchFilters() {
      const filtersObj = this.activeFilters.reduce((obj, key) => {
        obj[key] = this.params[key];
        return obj;
      }, {});
      if (this.activeFilters.length > 0) {
        sessionStorage.setItem('search_filters', JSON.stringify(filtersObj));
      } else {
        sessionStorage.removeItem('search_filters');
      }
    },
    getFilterLabel(key) {
      const filter = this.filters.find(f => f.key === key);
      return filter ? filter.name : key;
    }
  },
  watch: {
    selectedFilters: {
      handler(newValue) {
        if (newValue?.includes('some_key') && !this.params.some_key) {
          this.params.some_key = true;
        }
      },
      deep: true
    },
    params: {
      handler() {
        Object.keys(this.params).forEach(key => {
          if (this.params[key] === "") {
            this.params[key] = null;
            this.removeFilter(key);
          }
          else if (this.params[key] !== null && typeof this.params[key] === 'string' && key != 'notes' && key != 'description') {
            var text = this.params[key].split(/[\n,\t,]+/);
            this.params[key] = text
          }
        });
      },
      deep: true
    },
    'params.is_shipper': function (newVal) {
      this.handleIsShipperChange(newVal);
    },
    'params.private_label_indicator': function (newVal) {
      this.handleIsPrivateLabelChange(newVal);
    },
    'params.vmc_only': function (newVal) {
      this.handleVmcOnlyChange(newVal)
    }
  },
  created(){
    if (sessionStorage.getItem('search_filters')) {
      const stored = JSON.parse(sessionStorage.getItem('search_filters'));
      this.params = { ...this.params, ...stored };
      const activeKeys = Object.keys(stored).filter(key => Boolean(stored[key]));
      if (activeKeys.length > 0) this.selectedFilters.push(...activeKeys);
    }
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
</script>
<style>
.scrollable-select .v-select__selections {
  max-height: 100px;
  overflow-y: auto;
}

.scrollable-select .v-menu__content {
  max-height: 300px;
  overflow-y: auto;
}

.scrollable-select .v-select__content {
  max-height: 300px; /* Set max height for the dropdown content */
  overflow-y: auto;  
}
</style>
